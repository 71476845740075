@import "../../../assets/scss/color";
$errorBackground: white;
.FormMessageContainer {
  display: none;
  position: absolute;
  min-width: 10rem;
  max-width: 25rem;
  border-radius: 0.5rem;
  top: -2.7rem;
  right: 0;
  background-color: $primary;
  color: #fff;
  box-shadow: -3px -3px 6px rgba(0,0,0, .3);
  padding: .5rem 1rem;
  height: auto;
  transition: all ease-out 2s;
  z-index: 5;
  span {
    white-space: nowrap;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:before {
    content: '';
    position: absolute;
    border: 0.5rem solid $primary;
    z-index: 2;
    bottom: -0.5rem;
    right: 10%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    height: 0;
    width: 0;
  }
  &.middle-right {
    top: .5rem;
    right: .5rem;
    &:before {
      left: -0.5rem;
      top: 30%;
      bottom: unset;
      right: unset;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
}

.show, .form-group:hover .FormMessageContainer {
  display: block;
  &.error {
    background-color: $danger!important;
    &:before {
      border: 0.5rem solid $danger!important;
    }
  }
}
