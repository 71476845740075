@mixin ignore($ignoreList...) {
  //if only a single value given
  @if (length($ignoreList) == 1){
    //it is probably a list variable so set ignore list to the variable
    $ignoreList: nth($ignoreList,1);
  }
  //set up an empty $notOutput variable
  $notOutput: '';
  //for each item in the list
  @each $not in $ignoreList {
    //generate a :not([ignored_item]) segment for each item in the ignore list and put them back to back
    $notOutput: $notOutput + ':not(#{$not})';
  }
  //output the full :not() rule including all ignored items
  &#{$notOutput} {
    @content;
  }
}

@mixin fill-parent {
  width: 100%;
  height: 100%;
}

@mixin rotate-clockwise($i) {
  transform: rotate($i * 45deg);
  -webkit-transform: rotate($i * 45deg);
}
