@import "assets/scss/reset";
@import "assets/scss/vendor";
@import "assets/scss/font";
@import "assets/scss/mixins";
@import "assets/scss/grid";

html{
  font-size: 62.5%
}

body {
  --theme-color: #131523;
  --theme-bg: #F5F7FD;
  --header-height: 7rem;
  --footer-height: 5rem;
  --fine-border-color: rgba(0, 0, 0, 0.1);
  --min-main-content-height-header: calc(100vh - var(--header-height));
  --min-main-content-height-footer: calc(100vh - var(--footer-height));
  --min-main-content-height-header-footer: calc(100vh - (var(--header-height) + var(--footer-height)));
  &.dark-theme {
    --theme-color: #F5F7FD;
    --theme-bg: #131523;
  }
  max-width: 100vw;
  max-height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  letter-spacing: .00688rem;
  color: var(--theme-color);
  background-color: var(--theme-bg);
  @include set-font();
  @media screen and (max-width: 560px) {
    font-size: 1.1rem!important;
  }
}

html, body, .fill-parent {
  @include fill-parent;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 96%;
  margin: 0 15px;
}

::-webkit-scrollbar {
  width: .6rem;
  height: .6rem;
  background-color: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba($light, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba($primary, 0.8);
  border-radius: .5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

//@media screen and (min-width: 600px) {
//  html {
//    font-size: 62.5%;
//  }
//}
//
//@media screen and (min-width: 860px) {
//  html {
//    font-size: 65%;
//  }
//}

@media screen and (min-width: 1000px) {
  html {
    font-size: 75%;
  }
}

@media screen and (min-width: 1200px) {
  html {
    font-size: 87.5%;
  }
}

@media screen and (min-width: 1500px) {
  html {
    font-size: 112.5%;
  }
}

@media screen and (min-width: 2000px) {
  html {
    font-size: 150%;
  }
}

.min-content-height-header {
  min-height: var(--min-main-content-height-header);
}
.min-content-height-footer {
  min-height: var(--min-main-content-height-footer);
}
.min-content-height-header-footer {
  min-height: var(--min-main-content-height-header-footer);
}


.btn {
  padding: 0.5rem 1rem;
  height: auto;
  transition: transform .5s, box-shadow .5s;

  &:focus, &:active {
    box-shadow: none;
    outline: none;
  }
}

.lift, .btn:not(.no-lift) {
  transition: box-shadow .2s cubic-bezier(.4,0,.2,1);
  &:hover {
    box-shadow: 0 0.625rem 0.875rem -0.3125rem rgba(0,0,0, .3);
  }
}

i.arrow {
  border: solid #7E84A3;
  border-width: 0 2.5px 2.5px 0;
  display: inline-block;
  padding: 2.5px;
  margin-right: .5rem;
  transition: .5s transform ease-in-out;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  &.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
}

.form-control:focus, .btn:focus {
  box-shadow: none;
  //border-color: inherit;
}

.form-control {
  background-color: #FFFFFF;
  padding: 1.25rem 1rem;
  &:not(.no-thick-border):hover {
    border-left: .8rem solid rgba($primary, .6);
  }
  &:active, &:focus {
    box-shadow: none;
    &:not(.no-thick-border) {
      border-left: .8rem solid $primary;
    }
  }
  &.border-transparent {
    border: 1px solid transparent;
  }
  &.sme-invalid {
    border: 1px solid $danger;
    &:not(.no-thick-border):hover {
      border-left: .8rem solid rgba($danger, .6);
    }
    &:not(.no-thick-border):active, &:not(.no-thick-border):focus {
      box-shadow: none;
      border-left: .8rem solid $danger;
    }
  }
}

.cursor-pointer{
  cursor: pointer;
}

.GraphWrapper {
  background-color: #FFFFFF;
}

.faded {
  color: #4B4C4F;
}

.btn {
  .btn-arrow {
    width: 1rem;
    display: inline-block;
  }
  &.btn-outline-deep-light {
    border: 1px solid #A4ADC8;
  }
  &.btn-light-secondary{
    background: #FFF084;
  }
  &.btn-cancel {
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
    border: 1px solid #C4CDD5;
    box-sizing: border-box;
    box-shadow: 0 1px 0 rgba(22, 29, 37, 0.05);
    border-radius: 3px;
    &:hover {
      background: linear-gradient(180deg, #F9FAFB 0%, #E9EAEB 100%);
    }
  }
}

button:focus {
  outline: none;
}


.clamp-paragraph {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
  max-height: 8rem;
}
