@import "../../../assets/scss/color";
.Aside {
  display: flex;
  transition: all .5s;
  height: 100%;
  overflow-y: auto;


  .Nav {
    height: inherit;
  }

  .NavItem {
    padding: 2rem 0;
    &.NavIcon {
      padding: 0;
      display: flex;
      align-items: center;
      height: var(--header-height);
    }
  }

  .NavContent {
    display: flex;
    height: 100%;
    flex-direction: column;
    margin: auto;
    width: 100%;
  }

  .anchor {
    $anchorWidth: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--header-height);
    position: absolute;
    top: 0;
    right: -$anchorWidth;
    width: $anchorWidth;
    cursor: pointer;
    font-size: 1.4rem;
    span {
      width: inherit;
      text-align: center;
    }
  }

  .Icon {
    height: 1.2rem;
    margin-right: .5rem;
    object-position: center;
    object-fit: contain;
  }

  .NavBody {
    display: flex;
    flex-direction: column;
    width: auto;
    font-size: 0.9rem;
    .NavItem {
      color: #A4ADC8;
      padding: .4rem 1rem;
      margin: .2rem 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      &:last-child {
        border-bottom: none;
      }
      &.Active, &:hover {
        background-color: $secondary;
        color: $primary!important;
        border-radius: .2rem;
        font-weight: 600;
        img {
          filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(186deg) brightness(108%) contrast(101%);
        }
      }
    }
  }

  a, a:hover, a:active {
    color: inherit;
  }
}
