@import "../../../assets/scss/color";
//input.form-control.sme-invalid,
//input.form-control.sme-invalid:hover,
//input.form-control.sme-invalid:focus,
//input.form-control.sme-invalid:active {
//  border: 1px solid #ce3300;
//}

.no-border-left:not(.sme-invalid):not(:active):not(:focus){
  border-left: none;
}

.no-border-right:not(.sme-invalid):not(:active):not(:focus){
  border-right: none;
}

.half-width {
  width: 48.5%;
  display: inline-block;
  &.left {
    margin-right: 1.5%;
  }
  &.right {
    margin-left: 1.5%;
  }
}

input.form-control {
  padding: .6rem 1rem;
  height: auto;
}

input.form-control:-webkit-autofill,
input.form-control:-webkit-autofill:hover,
input.form-control:-webkit-autofill:focus,
textarea.form-control:-webkit-autofill,
textarea.form-control:-webkit-autofill:hover,
textarea.form-control:-webkit-autofill:focus,
select.form-control:-webkit-autofill,
select.form-control:-webkit-autofill:hover,
select.form-control:-webkit-autofill:focus {
  border: 1px solid $text-color;
  -webkit-text-fill-color: $text-color;
  -webkit-box-shadow: 0 0 0px 1000px rgba($primary, 0.3) inset;
  transition: background-color 5000s ease-in-out 0s;
}
