.Dashboard {
  width: 100vw;
  --aside-width: 16rem;
  .badge-paid {
    background-color: #C4F8E2;
    color: #06A561;
  }
  .badge-pending {
    background-color: #EAECF5;
    color: #5A607F;
  }
  .aside {
    top: 0;
    width: var(--aside-width);
    height: 100%;
    z-index: 1;
  }
  #mainContent {
    width: calc(100% - var(--aside-width));
  }
}
