$font-family : 'Inter', sans-serif;
$font-sizes: 0.2rem, 0.5rem, 0.7rem, 0.85rem, 1.0rem, 1.25rem, 1.5rem, 2.0rem, 2.5rem, 3.2rem, 4.5rem;
$font-sizes-sm: 0.2rem, 0.5rem, 0.65rem, 0.85rem, 1.0rem, 1.1rem, 1.2rem, 1.5rem, 1.75rem, 2rem, 2.5rem;
$font-weights: 300, 400, 500, 600, 700, 800, 900;
$size-identifier: xxsm, xsm, sm, default, normal, smd, md, bg, mbg, xbg, xxbg;
$weight-identifier: light, normal, smbd, mbd, bd, xbd, xxbd;


@mixin set-font($size: 1rem, $weight: 400, $family: $font-family) {
  font-size: $size!important;
  line-height: $size * 1.75 !important;
  font-weight: $weight;
  font-family: $family;
}

@for $s from 1 through length($font-sizes) {
    .font-s-#{nth($size-identifier, $s)} {
       font-size: nth($font-sizes-sm, $s) !important;
      @media screen and (min-width: 560px){
        font-size: nth($font-sizes, $s) !important;
        line-height: 1.4 !important;
      }
    }}

@for $w from 1 through length($font-weights) {
  .font-w-#{nth($weight-identifier, $w)} {
    font-weight: nth($font-weights, $w) !important; }}
