/* Custom grid start here */
.sme-grid {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(1, minmax(10rem, 1fr));
  grid-auto-rows: auto;
  grid-row-gap: 1.6rem;
  grid-column-gap: 1.5rem;
  &.tiny-gap {
    grid-column-gap: 5px;
    grid-row-gap: 1rem;
  }
}


@media screen and (min-width: 520px){
  .sme-grid {
    grid-template-columns: repeat(2, minmax(10rem, 1fr));
  }
}

@media screen and (min-width: 720px){
  .sme-grid:not(.max-col-2) {
    grid-template-columns: repeat(3, minmax(10rem, 1fr));
  }
  .sme-grid.wide-grid {
    grid-template-columns: repeat(2, minmax(10rem, 1fr));
  }
}

@media screen and (min-width: 900px){
  .sme-grid.slim-grid {
    grid-template-columns: repeat(4, minmax(10rem, 1fr));
  }
  .sme-grid.wide-grid {
    grid-template-columns: repeat(3, minmax(10rem, 1fr));
  }
}

@media screen and (min-width: 1200px){
  .sme-grid:not(.max-col-2):not(.max-col-3) {
    grid-template-columns: repeat(4, minmax(10rem, 1fr));
  }
}

@media screen and (min-width: 2000px){
  .sme-grid:not(.max-col-2):not(.max-col-3) {
    grid-template-columns: repeat(5, minmax(10rem, 1fr));
  }
}
